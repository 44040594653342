import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ContextService, ContextType } from '../services/context.service';

@Directive({
  standalone: true,
  selector: '[parkourActiefVoorMezelf]',
})
export class ActiefVoorMezelfDirective implements OnInit, OnDestroy {
  hasView = false;
  private subscription?: Subscription;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private readonly contextService: ContextService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.contextService.context$.subscribe((context) =>
      this.onContextTypeChanged(context.type),
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private onContextTypeChanged(contextType: ContextType) {
    if (contextType === 'voor-mezelf' && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (contextType !== 'voor-mezelf' && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
