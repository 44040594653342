import { MeldingType } from 'parkour-web-app-dto';

const wisselTeamMeldingen = {
  specificMeldingen: ['BEVESTIGING_GEWEIGERD', 'UITGENODIGD', 'VERWIJDERD_UIT_TEAM'],
  children: {
    GEBLOKKEERD_TEAM_PAGE: {
      specificMeldingen: ['GEBLOKKEERD'],
    },
  },
} as const;

const kalenderPageMeldingen = {
  specificMeldingen: ['AFSPRAAK_VERWIJDERD'],
  children: {
    AFSPRAAK_PAGE: {
      specificMeldingen: ['AFSPRAAK_AANGEMAAKT', 'AFSPRAAK_GESTART'],
    },
  },
} as const;

const mijnProfielPageMeldingen = {
  specificMeldingen: ['PROFIEL_WORDT_VERWIJDERD_HERINNERING'],
  children: {},
} as const;

const teamMeldingen = {
  specificMeldingen: [
    'TEAM_VERLATEN',
    'GEDEBLOKKEERD',
    'UITNODIGING_GEWEIGERD',
    'UITNODIGING_BEVESTIGD',
    'UITNODIGING_AANVAARD',
  ],
} as const;

const meldingenForPageConfiguration = {
  BERICHTEN_TAB: {
    specificMeldingen: [],
    children: {
      GESPREKKEN_PAGE: {
        specificMeldingen: [],
        children: {
          GESPREK_PAGE: {
            specificMeldingen: ['NIEUW_CHAT_BERICHT', 'NIEUW_CHAT_REACTIE', 'VIDEOGESPREK_GESTART'],
          },
        },
      },
    },
  },
  PROFIEL_TAB_MOBILE: {
    specificMeldingen: [],
    children: {
      WISSEL_TEAM: wisselTeamMeldingen,
      KALENDER_PAGE: kalenderPageMeldingen,
      MIJN_PROFIEL_PAGE: mijnProfielPageMeldingen,
      PROFIEL_PAGE: {
        specificMeldingen: [],
        children: {
          MIJN_TEAM_PAGE: teamMeldingen,
        },
      },
    },
  },
  PROFIEL_TAB_DESKTOP: {
    specificMeldingen: [],
    children: {
      KALENDER_PAGE: kalenderPageMeldingen,
    },
  },
  VERHAAL_TAB: {
    specificMeldingen: [],
    children: {
      VERHAAL_PAGE: {
        specificMeldingen: ['GEBEURTENIS_GESUGGEREERD'],
        children: {
          PARKOUR_GEBEURTENIS_PAGE: {
            specificMeldingen: ['GEBEURTENIS_GEDEELD'],
          },
          EYOUTH_GEBEURTENIS_PAGE: {
            specificMeldingen: ['NIEUWE_EYOUTH_GEBEURTENIS', 'JEUGDHULPHISTORIEK_GEDEELD'],
          },
          DRIE_KOLOMMEN_DOCUMENT_PAGE: {
            specificMeldingen: ['NIEUW_DRIE_KOLOMMEN_DOCUMENT'],
          },
        },
      },
    },
  },
  DOELEN_TAB: {
    specificMeldingen: [],
    children: {
      DOELEN_PAGE: {
        specificMeldingen: ['DOEL_GESUGGEREERD'],
        children: {
          DOEL_PAGE: {
            specificMeldingen: ['DOEL_VOLTOOID', 'DOEL_EMOJI_REACTIE_ADDED', 'DOEL_GEDEELD'],
            children: {
              DOEL_PAGE_GESPREK: {
                specificMeldingen: ['DOEL_NIEUW_BERICHT', 'DOEL_NIEUW_BERICHT_REACTIE'],
              },
            },
          },
        },
      },
    },
  },
} as const;

type MeldingForPageConfig = ParseMeldingConfig<{
  children: typeof meldingenForPageConfiguration;
  specificMeldingen: [];
}>;

type MeldingConfigType = {
  readonly specificMeldingen: readonly MeldingType[];
  readonly children?: Record<string, MeldingConfigType>;
};

// Niet te veel over nadenken: https://stackoverflow.com/questions/50374908/transform-union-type-to-intersection-type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnionToIntersection<U> = (U extends any ? (x: U) => void : never) extends (x: infer I) => void
  ? I
  : never;

export type ArrayToObject<T extends readonly string[]> = {
  [key in T[number]]: key;
};

export type ParseMeldingConfig<Type extends MeldingConfigType> = Type extends {
  children: object;
}
  ? { [key in keyof Type['children']]: ParseMeldingConfig<Type['children'][key]> } & {
      specificMeldingen: ArrayToObject<Type['specificMeldingen']>;
      allMeldingen: ArrayToObject<Type['specificMeldingen']> &
        UnionToIntersection<
          ParseMeldingConfig<Type['children'][keyof Type['children']]>['allMeldingen']
        >;
    }
  : {
      allMeldingen: ArrayToObject<Type['specificMeldingen']>;
      specificMeldingen: ArrayToObject<Type['specificMeldingen']>;
    };

function parseMeldingenConfig<Type extends MeldingConfigType>(
  config: Type,
): ParseMeldingConfig<Type> {
  const children = config.children
    ? Object.fromEntries(
        Object.entries(config.children).map(([k, v]) => [k, parseMeldingenConfig(v)]),
      )
    : {};

  const specificMeldingen = Object.fromEntries(
    config.specificMeldingen.map((meldingType) => [meldingType, meldingType]),
  );

  return {
    ...children,
    allMeldingen: Object.assign(
      { ...specificMeldingen },
      ...Object.values(children).map((c) => c.allMeldingen),
    ),
    specificMeldingen: specificMeldingen,
  } as unknown as ParseMeldingConfig<Type>;
}

function getMeldingenForPageConfiguration(): MeldingForPageConfig {
  return parseMeldingenConfig({
    children: meldingenForPageConfiguration,
    specificMeldingen: [],
  });
}

export const meldingenForPage = getMeldingenForPageConfiguration();
export const meldingenForTeamPage = parseMeldingenConfig(teamMeldingen);
export const meldingenForKalenderPage = parseMeldingenConfig(kalenderPageMeldingen);
export const meldingenForMijnProfielPage = parseMeldingenConfig(mijnProfielPageMeldingen);
export const meldingenForWisselTeamPage = parseMeldingenConfig(wisselTeamMeldingen);
