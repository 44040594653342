export const environment = {
  production: false,
  API_BASE_URL: 'https://parkour-dev.opgroeien.be',
  watWatBaseUrl: 'https://www.watwat.be',
  globalHeaderEmbedCode:
    'https://tni.widgets.burgerprofiel.dev-vlaanderen.be/api/v1/widget/14545545-2a96-42b5-8e4a-20a4eb1eeaa7',
  jitsiBaseUrl: 'jitsi.parkour-dev.opgroeien.be',
  jitsiApiEmbedCode: 'https://jitsi.parkour-dev.opgroeien.be/external_api.js',
  ongelezenMeldingenInterval: 30000,
  opentelemetry: {
    enabled: true,
    tracingUrl: 'https://otel.parkour-test.opgroeien.be/v1/traces',
  },
  logging: 'debug',
  matomo: {
    enabled: false,
    trackerUrl: '',
    siteId: 1,
  },
  mockGlobalHeader: false,
  debugOptionsEnabled: true,
};
