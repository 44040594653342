import { Injectable } from '@angular/core';
import { PusherService } from '../../bericht/service/pusher.service';
import { SucceservaringDto, SucceservaringPusherMeldingDto } from 'parkour-web-app-dto';
import {
  isSupportedSucceservaringType,
  SucceservaringType,
  SupportedSucceservaringType,
} from '../model/succeservaring';
import { LoggingService } from '../../core/logging.service';
import { ToastController } from '@ionic/angular';
import { Haptics, NotificationType } from '@capacitor/haptics';
import { isNativeApp } from '../../utils';
import { HttpClient } from '@angular/common/http';
import { catchError, filter, Observable, switchMap } from 'rxjs';
import AuthService from '../../authentication/service/auth.service';
import { AnalyticsService } from '../../analytics/analytics.service';
import { AnalyticsEvent } from '../../analytics/analytics-event.model';
import { environment } from '../../../environments/environment';

export type SucceservaringVisualization = {
  readonly icon: string;
  readonly titel: string;
  readonly beschrijving: string;
};

@Injectable({
  providedIn: 'root',
})
export class SuccesservaringenService {
  private succeservaringVisualizations: Record<
    SupportedSucceservaringType,
    SucceservaringVisualization
  > = {
    TEAMLID_GEACTIVEERD: {
      icon: '🙌',
      titel: 'Go team!',
      beschrijving: '1e teamlid geactiveerd',
    },
    UITNODIGING_AANVAARD: {
      icon: '🙌',
      titel: 'Go team!',
      beschrijving: '1e uitnodiging aanvaard',
    },
    ARTIKEL_BEWAARD: {
      icon: '📎',
      titel: 'Eentje om bij te houden!',
      beschrijving: '1e artikel bewaard',
    },
    HULPLIJN_BEWAARD: {
      icon: '👏',
      titel: 'Hulp en info bijhouden, da’s top!',
      beschrijving: '1e hulplijn bewaard',
    },
    DOEL_VOORGESTELD: {
      icon: '🚀',
      titel: 'Gouden idee!',
      beschrijving: '1e doel voorgesteld',
    },
    DOEL_TOEGEVOEGD: {
      icon: '🫡',
      titel: 'Missie gestart!',
      beschrijving: '1e doel toegevoegd',
    },
    GEBEURTENIS_VOORGESTELD: {
      icon: '✨',
      titel: 'Yes! Jij maakt dit verhaal compleet!',
      beschrijving: '1e gebeurtenis voorgesteld',
    },
    GEBEURTENIS_TOEGEVOEGD: {
      icon: '📔',
      titel: 'Het begin van een nieuw dagboek!',
      beschrijving: '1e gebeurtenis toegevoegd',
    },
    EMOJI_REACTIE_DOEL_TOEGEVOEGD: {
      icon: '❤️',
      titel: 'BAM!',
      beschrijving: '1e emoji-reactie op een doel toegevoegd',
    },
    AFSPRAAK_TOEGEVOEGD: {
      icon: '🔮',
      titel: 'Ik zie... de toekomst',
      beschrijving: '1e afspraak toegevoegd',
    },
    BEELDBELAFSPRAAK_GEPLAND: {
      icon: '🤳',
      titel: 'Face-to-face, maar dan digitaal!',
      beschrijving: '1e afspraak beeldbellen gepland',
    },
    DOEL_STAP_AFGEVINKT: {
      icon: '✅',
      titel: 'Eén stap dichter bij wereldheerschappij... of je doel! Check!',
      beschrijving: '1e stap afgevinkt',
    },
    NOTITIE_BEWAARD: {
      icon: '🧠',
      titel: 'Nice! Je brein staat op papier!',
      beschrijving: '1e notitie bewaard',
    },
    PROFIEL_OUDER_DAN_3_DAGEN: {
      icon: '🍌',
      titel: 'Al meer dan 3 dagen op PARKOUR! Hier is een banaan!',
      beschrijving: 'Minstens 3 dagen op PARKOUR',
    },
  };

  constructor(
    private readonly pusherService: PusherService,
    private readonly loggingService: LoggingService,
    private readonly toastController: ToastController,
    private readonly httpClient: HttpClient,
    private readonly authService: AuthService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  public initialize() {
    this.pusherService
      .createPusherObservableForEventOnProfielChannel<SucceservaringPusherMeldingDto>(
        'nieuwe-succeservaring',
      )
      .subscribe((succesErvaringMelding) => this.onSucceservaringReceived(succesErvaringMelding));

    this.authService.user$
      .pipe(
        filter((user) => user.type === 'aangemeld'),
        switchMap(() => this.getSucceservaringen()),
        catchError((error) => {
          this.loggingService.error('Failed to get succeservaringen', error);
          return [];
        }),
      )
      .subscribe((succeservaringen) => {
        succeservaringen
          .filter(
            (succeservaring) =>
              succeservaring.lastReadCount !== succeservaring.count &&
              succeservaring.lastReadCount === 0,
          )
          .forEach((succeservaring) =>
            this.onSucceservaringReceived({ type: succeservaring.type }),
          );
      });
  }

  private markeerSucceservaringTypeAlsGelezen(type: SucceservaringType) {
    return this.httpClient.put<void>(
      `${environment.API_BASE_URL}/api/succeservaringen/${type}/markeer-als-gelezen`,
      {},
    );
  }

  private getSucceservaringen(): Observable<SucceservaringDto[]> {
    return this.httpClient.get<SucceservaringDto[]>(
      `${environment.API_BASE_URL}/api/succeservaringen`,
    );
  }

  private onSucceservaringReceived = async (event: SucceservaringPusherMeldingDto) => {
    if (!isSupportedSucceservaringType(event.type)) {
      this.loggingService.error(`Received unsupported succeservaring type: ${event.type}`);
      return;
    }

    this.markeerSucceservaringTypeAlsGelezen(event.type).subscribe();

    await new Promise((resolve) => setTimeout(resolve, 500)); // Wait a bit to make it not appear immediately, better ux imo

    const visualization = this.succeservaringVisualizations[event.type];

    let headerHeight = 0;

    document.querySelectorAll('.ion-page:not(.ion-page-hidden) > ion-header').forEach((header) => {
      if (header instanceof HTMLElement && header.offsetHeight > headerHeight) {
        headerHeight = header.offsetHeight;
      }
    }); // On mobile there is always an empty header on the page?

    document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);

    const toast = await this.toastController.create({
      cssClass: 'ion-toast--succeservaring',
      header: `${visualization.icon} ${visualization.titel}`,
      message: visualization.beschrijving,
      duration: 7000,
      position: 'top',
      positionAnchor: 'toast-anchor',
      swipeGesture: 'vertical',
    });

    toast.present();

    this.analyticsService.trackEvent(
      new AnalyticsEvent('succeservaringen', 'succeservaringGetoond', event.type),
    );

    if (isNativeApp()) {
      await Haptics.notification({ type: NotificationType.Success });
    }
  };
}
