// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video {
	position: relative;

	display: flex;
	/* Padding hack to fix aspect ratio of 16/9 */
	padding-top: 56.66%;
}

	.video youtube-player-placeholder,
	.video iframe {
		position: absolute;
		top: 0;
		left: 0;

		width: 100% !important;
		height: 100% !important;
	}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBhcmtvdXItdmlkZW8tY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtDQUNDLGtCQUFrQjs7Q0FFbEIsYUFBYTtDQUNiLDZDQUE2QztDQUM3QyxtQkFBbUI7QUFXcEI7O0NBVEM7O0VBRUMsa0JBQWtCO0VBQ2xCLE1BQU07RUFDTixPQUFPOztFQUVQLHNCQUFzQjtFQUN0Qix1QkFBdUI7Q0FDeEIiLCJmaWxlIjoicGFya291ci12aWRlby1jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnZpZGVvIHtcblx0cG9zaXRpb246IHJlbGF0aXZlO1xuXG5cdGRpc3BsYXk6IGZsZXg7XG5cdC8qIFBhZGRpbmcgaGFjayB0byBmaXggYXNwZWN0IHJhdGlvIG9mIDE2LzkgKi9cblx0cGFkZGluZy10b3A6IDU2LjY2JTtcblxuXHR5b3V0dWJlLXBsYXllci1wbGFjZWhvbGRlcixcblx0aWZyYW1lIHtcblx0XHRwb3NpdGlvbjogYWJzb2x1dGU7XG5cdFx0dG9wOiAwO1xuXHRcdGxlZnQ6IDA7XG5cblx0XHR3aWR0aDogMTAwJSAhaW1wb3J0YW50O1xuXHRcdGhlaWdodDogMTAwJSAhaW1wb3J0YW50O1xuXHR9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/components/parkour-video-content/parkour-video-component.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;;CAElB,aAAa;CACb,6CAA6C;CAC7C,mBAAmB;AAWpB;;CATC;;EAEC,kBAAkB;EAClB,MAAM;EACN,OAAO;;EAEP,sBAAsB;EACtB,uBAAuB;CACxB;;AAGD,oyBAAoyB","sourcesContent":[".video {\n\tposition: relative;\n\n\tdisplay: flex;\n\t/* Padding hack to fix aspect ratio of 16/9 */\n\tpadding-top: 56.66%;\n\n\tyoutube-player-placeholder,\n\tiframe {\n\t\tposition: absolute;\n\t\ttop: 0;\n\t\tleft: 0;\n\n\t\twidth: 100% !important;\n\t\theight: 100% !important;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
